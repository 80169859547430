import "./styles.css";

export default function WorkTiles() {
  return (
    <div className="workTiles">
      <h1>Projects</h1>
      <div className="workPieces">
        <div>Google Docs</div>
        <div>Chat Bot</div>
        <div>Firemap</div>
      </div>
    </div>
  );
}
