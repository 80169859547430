import "./styles.css";
import WorkLinks from "../WorkLinks";

export default function Header() {
  return (
    <div className="header">
      <WorkLinks />
    </div>
  );
}
