import GitHubLogo from "./GitHub-Mark-Light-64px.png";
import LinkedInLogo from "./linkedIn.png";
import MailIcon from "./mail-icon.png";
import "./styles.css";

export default function WorkLinks() {
  return (
    <div className="workLinks">
      <a href="https://www.github.com/dyldlewis">
        <img className="logo" src={GitHubLogo} />
      </a>
      <img className="logo" src={LinkedInLogo} />
    </div>
  );
}
